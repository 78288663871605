import Image from 'next/image';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import {
  ModalButton as Button,
  ModalHeading as Heading,
} from '@/components/common/Modal/components';
import { Modal } from '@/components/common';
import bingoCry from '@/assets/illustrations/bingo-cry.png';
import ROUTES from '@/lib/routes';

interface NoAccessModalProps {
  open: boolean;
  callback?: string;
  onClose: (status: boolean) => void;
}

function NoAccessModal({ open, callback, onClose }: NoAccessModalProps) {
  const { t } = useTranslation('modal');
  return (
    <Modal
      title={t('no_access.label.header')}
      size="sm"
      open={open}
      onClose={onClose}
      titleClassName="text-center !text-[12px] sm:!text-lg"
    >
      <div className="flex justify-center items-center flex-col text-center text-white">
        <Image src={bingoCry} alt={t('no_access.label.header')} className="w-[250px] my-5" />
        <Heading className="mb-8 whitespace-pre-line">{t('no_access.label.description')}</Heading>
        <Link href={ROUTES.SIGN_IN(callback)}>
          <Button size="md" colorScheme="orange">
            {t('no_access.label.button')}
          </Button>
        </Link>
      </div>
    </Modal>
  );
}

export default NoAccessModal;
