import dynamic from 'next/dynamic';

// export { default as Button } from './Button';
// export { default as DropDown } from './Dropdown/DropDown';
// export { default as Avatar } from './Avatar';
// export { default as Input } from './Input';
// export { default as Modal } from './Modal';
// export { default as Dropzone } from './Dropzone';
// export { default as YoutubeEmbed } from './YoutubeEmbed';
// export { default as BingoRocket } from './BingoRocket';

export const Button = dynamic(() => import('./Button'), {
  ssr: true,
});

export const DropDown = dynamic(() => import('./Dropdown/DropDown'), {
  ssr: true,
});

export const Avatar = dynamic(() => import('./Avatar'), {
  ssr: true,
});

export const Input = dynamic(() => import('./Input'), {
  ssr: true,
});

export const Modal = dynamic(() => import('./Modal'), {
  ssr: true,
});

export const Dropzone = dynamic(() => import('./Dropzone'), {
  ssr: true,
});

export const YoutubeEmbed = dynamic(() => import('./YoutubeEmbed'), {
  ssr: true,
});

export const BingoRocket = dynamic(() => import('./BingoRocket'), {
  ssr: true,
});

export const NeonText = dynamic(() => import('./NeonText'), {
  ssr: true,
});

export const Alert = dynamic(() => import('./Alert'), {
  ssr: true,
});

export const StrokeText = dynamic(() => import('./StrokeText'), {
  ssr: true,
});
